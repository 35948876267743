import axios from "axios";
import VueCookies from 'vue-cookies';
import SERVER from '@/API/url';

const userStore = {
    namespaced: true,
    state: {
        
    },
    getters: {

    },
    mutations: {

    },
    actions: {

        // 로그인
        login: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/account/LoginSubmit', params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 회원가입
        register: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/createuser', params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 회원정보 가져오기
        getUserInfo: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/GetUserInfo/', params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 회원정보 가져오기
        getUserInfoWithUseruid: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/GetUserInfoWithUseruid/'+params.useruid, params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 사용자 정보 수정
        updateUser: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/UpdateUser', params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },
        // 사용자 정보 탈퇴
        leaveUser: ({commit}, params) => {

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/LeaveUser', params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 사용자 계정 확인
        checkUserAccount: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/CheckUserAccount/', params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },
        
        // SNS 회원가입
        createSNSUser: ({commit}, params) => { 

            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/CreateSNSUser/', params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },
        
        // 전화번호 저장 여부 확인
        checkUserPhone: ({commit}, params) => {
            
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + "/api/CheckUserPhone?useruid="+params.useruid,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res.data);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },
        
    }
}

export default userStore
